<template>
    <v-container fluid>
<v-row>
    <v-col>
 <v-form
    ref="orgForm"
    v-model="valid"
    
  >
      <v-card flat outlined  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
        <v-card-title class="headline">
       {{ $store.getters.getTextMap().change_organisation }}
        </v-card-title>
        <v-card-text>
            <v-text-field
      v-model="form.organisation_id"
      :counter="40"
      :rules="nameRules"
      :label="$store.getters.getTextMap().organisation_id"
      required
 
    ></v-text-field>
    
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
           
            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().submit }} 
          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().cancelbtnColor"
           :dark="$store.getters.getColorPalette().isDark"
            @click="onReset"
          >
          {{ $store.getters.getTextMap().cancel}} 
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-col>
</v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
export default {
    name:'ChangeOrganisation',
    data(){
        return {
            form:{
                organisation_id:null
            },
                loading:false,
                info:null,
                showDismissibleAlert:false,
                valid:false,
        nameRules: [
        v => !!v || this.$store.getters.getTextMap().name_is_required,
        v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
        v => (v && v.length <= 40 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_40_characters,
      ],
        }
    },
    methods:{
        onSubmit(){
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
      axios
        .post(this.$store.state.api + "changeOrganisation", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setJWT", response.data.jwt);
            localStorage.setItem('enture_token',response.data.jwt)
            this.$store.commit("setLoggedIn", true)
            //console.log(response.data.jwt);
            this.$store.dispatch("refresh");
            
            //setTimeout(()=>{this.$store.dispatch("refresh")}, 30000);
            //console.log("redirecting to dashboard")
            
            this.onReset()
            this.$router.push("/dashboard");
            this.$emit('close')
          } else {
            this.info = response.data.error;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            
            
            
        }
    }
}
</script>
<style scoped>

</style>