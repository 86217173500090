<template>
  
    <span>
        
      <v-btn
      @click="dialog=!dialog"
      :dark="$store.getters.getColorPalette().isDark"
      text
       small
       outlined
      :background-color="$store.getters.getColorPalette().background1ColorCode"
     :color="$store.getters.getColorPalette().btnborderColorCode"
>
                    
{{$store.getters.getTextMap().switch_organisation}}

                     <v-icon :color="$store.getters.getColorPalette().iconColor">
                        mdi-compare-horizontal
                    </v-icon> 
                </v-btn>
      
          <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card :dark="$store.getters.getColorPalette().isDark">
    <v-toolbar
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
           :dark="$store.getters.getColorPalette().isDark"
            
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{$store.getters.getTextMap().switch_organisation}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
             :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      <SwitchOrganisation v-on:close="onClose" />
    </v-card>
    </v-dialog>
    </span>
     
</template>

<script>
import SwitchOrganisation from '@/components/SwitchOrganisation'
  export default {
      name:'SwitchOrganisationModal',
      components:{
          SwitchOrganisation
      },
    data () {
      return {
        dialog: false
      }
    },
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
            }
        }

    }
</script>
<style >

</style>