<template>
    <!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper"> Settings</h1> -->
    <v-container fluid>
        <v-row no-gutters>
            <!-- <v-col align-self="center" align="center" v-if="$store.state.user">
                <ChangePasswordModal />
            </v-col> -->
            <v-col align-self="center" align="center" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
                <SwitchOrganisationModal />
            </v-col>
            <!-- <v-col >
                <ToggleUserView v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])"/>
            </v-col> -->
            <v-col align-self="center" align="center" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
                <v-btn 
                    :dark="$store.getters.getColorPalette().isDark"
                    text
                    small
                    outlined
                    :color="$store.state.clientSafety?'green':'red'"
                    @click="$store.commit('toggleClientSafety')"  
                >
                    {{$store.state.clientSafety?'Turn Off':'Turn On'}} Client Safety</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.user && $store.state.settingMode &&  $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])">
            <v-col>
                <ChangeOrganisation />
            </v-col>
        </v-row>
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col>
                <CreateOrganisation />
            </v-col>
        </v-row>
                <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col>
                <AddOEMLogo />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ChangeOrganisation from '@/components/ChangeOrganisation'
import CreateOrganisation from '@/components/account_components/CreateOrganisation'
import SwitchOrganisationModal from '@/components/modals/SwitchOrganisationModal'
import AddOEMLogo from '@/components/account_components/AddOEMLogo'
// import ToggleUserView from '@/components/ToggleUserView'
// import ChangePasswordModal from '@/components/modals/ChangePasswordModal'
export default {
    name:'Settings',
    data(){
        return {}
    },
    components:{
        ChangeOrganisation,
        CreateOrganisation,
        // ToggleUserView,
        SwitchOrganisationModal,
        AddOEMLogo
        // ChangePasswordModal,
    },
    mounted() {
        document.title='Settings'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
